<template>
  <div class="app-container">
    <search-header :query="query" @search="searchChange" />
    <div class="export mb20" v-if="checkPermission('externaladmin:financialManagement:monthDetailExport')">
      <el-button type="primary" size="small" @click="orderExport" :loading="loading">导出账单</el-button>
    </div>
    <CommonTable
      :selection="false"
      :tableLoading="tableLoading"
      :cols="cols"
      :infoData="data"
      @sort-change="sortChange"
    >
      <template #updateTimeSlot="{ scoped: row }"> {{ parseTime(row[row.prop]) }} </template>
      <template #nickName="{ scoped: row }"> {{ getNickName(row) }} </template>
      <template #isRepayment="{ scoped: row }"> {{ row.isRepayment === 0 ? '未还款' : '已还款' }} </template>
      <el-table-column fixed="right" label="操作" width="300px" align="center">
        <template slot-scope="{ row }">
          <color-text-btn
            v-if="checkPermission('externaladmin:financialManagement:monthDetail')"
            @click="handleDetail(row)"
            >详情</color-text-btn
          >
        </template>
      </el-table-column>
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </div>
</template>

<script>
import saveAs from 'jszip/vendor/FileSaver.js'
import searchHeader from './module/searchHeader.vue'
import initDataMixin from '@/mixins/initData'
import { parseTime } from '@/utils'
import { balanceCols as cols } from './module/cols'
import { exportMonthBill } from '@/api/financial/userCredit'
import { exportWrap } from '@/utils'

export default {
  components: { searchHeader },
  mixins: [initDataMixin],
  data() {
    return {
      cols,
      sup_this: this,
      url: '/externaladmin/financeService/creditMonthBill/basicList',
      query: {
        isReturnRelatedData: 1 //1表示获取全部月结账单
      },
      loading: false
    }
  },
  methods: {
    // 导出账单
    async orderExport() {
      await exportWrap(exportMonthBill({ ...this.query }), '月结账单')
      // this.loading = true
      // try {
      //   let res = await awaitFormResolve(exportMonthBill({ ...this.query }))
      //   console.log(res)
      //   if (res) {
      //     saveAs(res, `月结账单`)
      //     this.$message.success('导出成功')
      //   } else {
      //     this.$message.warning('无订单可导出，请输入正确数值后重试')
      //   }
      // } catch {
      // } finally {
      //   this.loading = false
      // }
    },
    parseTime,
    getNickName(data) {
      return data?.userDTO?.nickName
    },
    sortChange({ prop, order }) {
      if (prop == 'levelName') {
        const state = order == 'ascending' ? true : false
        this.mergeData = {
          orderItems: [
            {
              column: 'member_level',
              asc: state
            }
          ]
        }
        this.init()
      } else if (prop == 'accountAmount') {
        const state = order == 'ascending' ? true : false
        this.mergeData = {
          orderItems: [
            {
              column: 'account_amount',
              asc: state
            }
          ]
        }
        this.init()
      } else if ((prop = 'updateTime')) {
        const state = order == 'ascending' ? true : false
        this.mergeData = {
          orderItems: [
            {
              column: 'update_time',
              asc: state
            }
          ]
        }
        this.init()
      }
    },
    handleDetail(data) {
      const { id } = data
      this.$router.push({ path: '/financialManagement/userMonthBillsList', query: { id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
</style>
